import qs from 'qs'

export const queryMuxAssetById = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      filters: {
        id: {
          $eq: options.id
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `mux-video-uploader/mux-asset/?${query}`
}
