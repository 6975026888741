export const getParentUid = (indexUid: string) => {
  if (!indexUid) {
    return null
  }
  switch (indexUid) {
    case 'biennial-entry':
      return 'biennial-page'
    case 'education-entry':
      return 'education-page'
    case 'news-entry':
      return 'news-page'
    case 'publication-entry':
      return 'publications-page'
    case 'press-entry':
      return 'press-page'
    case 'urban-vision-entry':
      return 'urban-vision-page'
    case 'biennial-participant-entry':
      return 'participants-page'
  }
}
