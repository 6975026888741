<template>
  <div :class="$style.root">
    <shared-gtag-consent-component />

    <transition name="mask">
      <navigation-menu-component
        v-show="menuVisible"
        :is-open="menuVisible"
        @close-self="menuVisible = false"
      />
    </transition>

    <navigation-menu-button-component
      :data-menu-open="menuVisible ? '' : null"
      @click="menuVisible = !menuVisible"
    />

    <navigation-site-nav-component
      @on-toggle-search="searchVisible = !searchVisible"
    />

    <search-component
      v-show="searchVisible"
      :isOpen="searchVisible"
      @closeSelf="searchVisible = false"
    />

    <navigation-loading-indicator-component />

    <main :class="$style.page">
      <nuxt-page />
    </main>

    <shared-footer-component />

    <client-only>
      <core-light-box-component />
    </client-only>
  </div>
</template>

<script setup lang="ts">
const menuVisible = ref(false)
const searchVisible = ref(false)

const router = useRoute()
const path = computed(() => router?.path)
watch(path, () => {
  menuVisible.value = false
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;

  min-height: var(--100svh);
}

.page {
  flex: 1;

  display: flex;
  flex-direction: column;

  gap: calc(var(--font-small--line-height) * 4);
}

.page > * {
  flex: 1;

  display: flex;
  flex-direction: column;
}
</style>
