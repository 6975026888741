import qs from 'qs'

const fields = [
  'richText',
  'locale'
]

export const queryFooter = (options: queryOptions) => {
  const query = qs.stringify(
    {
      locale: options?.locale || 'en-US',
      filters: {
        slug: {
          $eq: options?.slug
        }
      },
      fields,
      populate: {
        links: {
          on: queryFragmentLinks
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `footer-entries?${query}`
}
