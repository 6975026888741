<template>
  <div :class="$style.root">
    <ul
      v-if="results?.length"
      :class="$style.results"
    >
      <li
        v-for="(group, index) in groupedResults"
        :key="group?.indexUid"
        :class="$style.hitGroup"
        :data-type="group?.indexUid"
      >
        <search-hits-group-label-component
          :indexUid="group?.indexUid"
        />

        <div v-if="group?.indexUid === 'group'">
          <ul :class="$style.groupList">
            <li
              v-for="(hit, index) in group?.hits"
            >
              <search-hits-hit-component
                :hit="hit"
                :uid="hit?.indexUid"
              />
            </li>
          </ul>
        </div>

        <ul
          v-else-if="group?.hits"
          :class="$style[getGroupCssClass(group?.indexUid)]"
        >
          <li
            v-for="(hit, index) in group?.hits"
          >
            <search-hits-hit-component
              :hit="hit"
              :uid="group?.indexUid"
            />
          </li>
        </ul>
      </li>
    </ul>

    <div
      v-else-if="query && !results?.length && !pending"
      :class="$style.notification"
      v-html="$t('search.noResults', { query: query })"
    />

    <div
      v-else-if="query && pending"
      :class="$style.notification"
    >
      {{ $t('shared.loading') }} <core-loading-component />
    </div>
  </div>
</template>

<script setup lang="ts">
import { sortBy } from 'lodash-es'

const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp
const { locale } = $i18n

const interimStore = useInterimStore()

const emit = defineEmits(['pending'])

const props = defineProps({
  queryString: {
    type: String,
    default: false
  }
})

const query = computed(() => props.queryString?.length ? props.queryString : undefined)

const { data, pending, clear  } = await useAsyncData(() => {
  return $fetch('/api/meilisearch', { query: { query: query?.value, locale: locale.value, editionIndex: interimStore?.editionIndex || null }})
}, { watch: [query] })

watch(data, () => {
  if (!query?.value) {
    clear()
  }
}, { immediate: true })


const results = computed(() => {
  return data?.value?.results?.filter((result: object) => result.estimatedTotalHits !== 0)
})

const groupedResults = ref(null)

watch(results, async (value) => {
  groupedResults.value = await setGroupedResults(value)
})

const setGroupedResults = async (results) => {
  if (!results) return

  let resultsGrouped = results?.reduce((acc: object, result: object) => {
    if ( result.indexUid?.endsWith('-page') || result.indexUid == 'page-entry') {

      if (!acc['group']) {
        acc['group'] = {
          indexUid: 'group',
          hits: [result]
        }
      } else {
        acc['group'].hits.push(result)
      }
    }
    else if (!acc[result.indexUid]) {
      acc[result.indexUid] = result
    }
    return acc
  }, {})

  resultsGrouped = Object.values(resultsGrouped)

  const flattenedGroups = resultsGrouped?.map(async (group) => {
    if (group?.indexUid === 'group') {
      const hits = group.hits?.map((group) => {
        return group.hits?.map((hit) => {
          return {
            indexUid: group.indexUid,
            ...hit
          }
        })
      })?.flat()

      hits?.sort((a, b) => {
        return a?.title?.localeCompare(b?.title)
      })

      return {
        indexUid: 'group',
        hits: hits
      }
    } else {
      const title = getSingleTypeTitle(getParentUid(group.indexUid))
      return {
        ...group,
        title: title
      }
    }
  })?.filter(Boolean)

  const resolvedFlattenedGroups = await Promise.all(flattenedGroups)

  const groupedGroup = resolvedFlattenedGroups?.find((group) => group.indexUid === 'group')
  let sortableGroups = resolvedFlattenedGroups?.filter((group) => group.indexUid !== 'group')

  sortableGroups.sort((a, b) => {
    return a?.title?.localeCompare(b?.title)
  })

  return [groupedGroup, ...sortableGroups]?.filter(Boolean)
}

const getGroupCssClass = (indexUid: string) => {
  if ([ 'news-entry', 'publication-entry', 'biennial-entry' ].includes(indexUid)) {
    return 'groupFlex'
  } if ([ 'biennial-participant-entry' ].includes(indexUid)) {
    return 'groupColumns'
  } else {
    return 'groupDefault'
  }
}
</script>

<style module>
.root {
}

.results {
  composes: reset-list font-size-small from global;
  padding-bottom: calc(var(--font-default--line-height) * 2);
}

.notification {
  composes: font-size-medium container from global;

  padding: calc(var(--unit--vertical) * 2) calc(var(--unit--horizontal) * 1);
  padding-top: 0;

  color: var(--color--secondary);

  text-align: center;
}

.notification mark {
  background-color: transparent;
  color: var(--color--primary);
}

.groupDefault {
  composes: reset-list container from global;
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit--vertical) * 2);
}

.groupFlex {
  composes: reset-list container-flex container-large  from global;
}

.groupList {
  composes: reset-list container from global;
  display: flex;
  flex-direction: column;
}

.groupColumns {
  composes: reset-list container from global;
  columns: 2;
  column-gap: var(--unit--horizontal);
}
</style>
