<template>
  <div
    :class="$style.root"
    @keyup.esc="$emit('closeSelf')"
    tabindex="0"
  >
    <Transition name="mask">
      <div
        :class="$style.content"
        ref="contentTarget"
        v-if="isOpen"
      >
        <div :class="$style.top">
          <input
            v-model="queryInput"
            :class="$style.input"
            :placeholder="$t('search.placeholder')"
            ref="inputTarget"
            tabindex="0"
            :disabled="isPending ? '' : null"
          >

          <button
            :class="$style.button"
            @click="$emit('closeSelf')"
          >
            <SvgoCross class="button-svg" />
          </button>
        </div>

        <search-hits-component
          :queryString="query"
          @pending="isPending = $event"
        />
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { refDebounced } from '@vueuse/core'
const route = useRoute()

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['closeSelf'])

const inputTarget = ref(null)
const contentTarget = ref(null)

const path = computed(() => route?.path)

const queryInput = ref(null)
const isPending = ref(false)
const query = refDebounced(queryInput, 750)

onClickOutside(contentTarget, (event) => emit('closeSelf'))

watch(() => props?.isOpen, (val) => {
  if (val) {
    setTimeout(() => {
      inputTarget.value?.focus()
      document.documentElement.setAttribute('data-overflow-hidden', '')
    }, 50)
  } else {
    queryInput.value = null
    query.value = null
    document.documentElement.removeAttribute('data-overflow-hidden')
  }
})

watch(path, () => {
  emit('closeSelf')
  queryInput.value = null
  query.value = null
})
</script>

<style module>
.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index--search);

  height: var(--100svh);
  width: 100vw;
  overflow: scroll;

  backdrop-filter: var(--backdrop-filter--light);
}

.content {
  --color--background: var(--color--background--default);
  --color--primary: var(--color--primary--default);
  --color--secondary: var(--color--secondary--default);

  background-color: var(--color--background);
  color: var(--color--primary);
}

.top {
  composes: container from global;

  background-color: var(--color--background);
  color: var(--color--primary);

  position: sticky;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: .25ch;

  padding: calc(var(--unit--vertical) * 2) calc(var(--unit--horizontal) * 1);
}

.button {
  composes: reset-button button-round from global;
}

.input {
  composes: reset-input font-size-large from global;

  padding-bottom: var(--stroke--default);
  border-bottom: var(--stroke--default) solid;

  /* min-width: 20rem; */
  max-width: calc(100% - var(--size--button) - .25ch);
  flex: 1;

  text-overflow: ellipsis;
}

.input[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.input::placeholder {
  color: var(--color--primary);
}

.linkToParent:empty {
  display: none;
}

</style>
