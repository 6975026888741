<template>
  <div
    :class="$style.root"
  >
    <layout-animated-underline-component
      :to="routeObject"
      :class="$style.title"
      :style="cssBackgroundColorTextDecoration"
    >
      <template #default>
        {{ entry?.title }}
      </template>
    </layout-animated-underline-component>

    <div
      v-if="relation && showRelation"
      :class="$style.relation"
    >
      {{relation?.title}}
    </div>

    <div
      v-if="firstParagraph"
      :class="$style.description"
      v-html="firstParagraph"
    />

    <layout-animated-underline-component
      :to="routeObject"
      :class="$style.moreLink"
      :inverted="true"
      :style="cssBackgroundColorTextDecoration"
    >
      {{ $t('shared.more') }}
    </layout-animated-underline-component>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()

const props = defineProps({
  entry: {
    type: Object,
    required: true
  },
  showRelation: {
    type: Boolean,
    default: false
  }
})
const relation = props.entry?.biennialEntry?.data || props.entry?.biennialEntry

const routeObject = getEducationSlugPath(props?.entry?.slug, relation?.slug)
const firstParagraph = baseStrapiGetFirstParagraphFromBlocks(props?.entry?.blocks, 40)

const colors = relation?.colors
const cssBackgroundColorTextDecoration = getCssBackgroundColorTextDecoration(colors)
</script>

<style module>
.root {
}

.link:hover .title {
  text-decoration-line: none;
}

.title {
  composes: font-size-medium from global;
}

.relation {
  color: var(--color--secondary--default);
}

.description {
  composes: font-size-small from global;
}

.moreLink {
  composes: font-size-small from global;
}
</style>
