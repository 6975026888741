<template>
  <shared-edition-thumbnail-component
    :data="entry"
    :scale="0.65"
  />
</template>

<script lang="ts" setup>
const props = defineProps({
  hit: Object,
})

const firstTextBlock = baseStrapiGetFirstParagraphFromBlocks(props?.hit?.biennialConcept?.blocks, 16)
const images = useFlattenDeep(
  props?.hit?.biennialConcept?.blocks?.map((block) => {
    return block?.files?.map((image) => {
      return image
    })
  })
)?.filter(image => image?.id)
const image = useSample(images) || props?.hit
const entry = {
  ...props?.hit,
  image: {
    ...image,
    orientation: getImageOrientation(image),
    richText: baseTruncateString(firstTextBlock, 28)
  }
}
</script>
