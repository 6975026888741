<template>
  <section
    :class="$style.root"
    v-show="consentSetting !== false && consentSetting !== true"
    id="cookie-consent"
  >
    <div :class="$style.content">
      <div>
         {{ $t('cookies.message') }}
      </div>
      <layout-animated-underline-component
        :class="$style.button"
        @click="[grantGtagConsent()]"
        :inverted="true"
      >
        <template #default>
          {{ $t('cookies.buttonAccept') }}
        </template>
      </layout-animated-underline-component>
      <layout-animated-underline-component
        :class="$style.button"
        @click="[revokeGtagConsent()]"
        :inverted="true"
      >
        <template #default>
          {{ $t('cookies.buttonRevoke') }}
        </template>
      </layout-animated-underline-component>
    </div>
  </section>
</template>

<script setup lang="ts">
  const { initialize, enableAnalytics, disableAnalytics } = useGtag()
  const consentSetting = useCookie('gtagConsent', { maxAge: 60 * 60 * 24 * 7 })

  const grantGtagConsent = () => {
    initialize()
    enableAnalytics()
    consentSetting.value = true
  }

  const revokeGtagConsent = () => {
    disableAnalytics()
    consentSetting.value = false
  }

  onMounted(() => {
    if(consentSetting.value === true) {
      initialize()
    }
  })
</script>

<style module>
.root {
  composes: font-size-small from global;
  position: fixed;

  right: 0;
  bottom: 0;
  left: 0;

  z-index: var(--z-index--cookie-consent);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  pointer-events: none;
}

.content {
  width: calc(var(--width--container) + var(--unit--horizontal)* 2);
  max-width: calc(100vw - var(--unit--horizontal)* 2);
  padding: calc(var(--unit--vertical) * 2) calc(var(--unit--horizontal) * 2);

  background-color: var(--color--primary);
  color: var(--color--background);

  display: flex;
  flex-wrap: wrap;
  gap: 1ch;
  justify-content: center;

  pointer-events: all;
}

.button {
  composes: reset-button from global;
}
</style>
